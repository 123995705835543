import "../styles/Buttons.css";
import React from 'react';
import OBSWebSocket from 'obs-websocket-js';

export default function StreamButton(props) {
  const obs = new OBSWebSocket();

  async function stopVirtualCamera() {
    try {
      await obs.call("StopVirtualCam")
    } catch (error) {
      obs.connect(`ws://${props.ipAddress}:${props.port}`, { rpcVersion: 1 }).then((data) => {
        console.log("Connected!")
        obs.call("StopVirtualCam")
      })
    }
  }

  async function startVirtualCamera() {
    try {
      await obs.call("StartVirtualCam")
    } catch (error) {
      obs.connect(`ws://${props.ipAddress}:${props.port}`, { rpcVersion: 1 }).then((data) => {
        console.log("Connected!")
        obs.call("StartVirtualCam")
      })
    }
  }

  return (
    <>
      {props.buttonActive === true && (
        <>
          <button
            onClick={() => stopVirtualCamera()}
            className="Button"
          >
            <div className="ringring"></div>

            Stop
          </button>
        </>
      )}

      {props.buttonActive === false && (
        <>
          <button
            className="Button"
            onClick={() => startVirtualCamera()}
          >
            <div className="ringring_offline"></div>
            Start
          </button>
        </>
      )}
    </>
  )
}
