import "../styles/Buttons.css";
import OBSWebSocket from 'obs-websocket-js';
import React from 'react';

export default function StreamButton2(props) {
  const obs = new OBSWebSocket();

  async function stopStream() {
    try {
      await obs.call("StopStream")
    } catch (error) {
      obs.connect(`ws://${props.ipAddress}:${props.port}`, { rpcVersion: 1 }).then(async (data) => {
        console.log("Connected!")
        await obs.call("StopStream");
      })
    }
  }

  async function startStream() {
    try {
      await obs.call("StartStream")
    } catch (error) {
      obs.connect(`ws://${props.ipAddress}:${props.port}`, { rpcVersion: 1 }).then(async (data) => {
        console.log("Connected!")
        await obs.call("StartStream");
      })
    }
  }


  return (
    <>
      {props.buttonActive === true && (
        <>
          <button
            onClick={() => stopStream()}
            className="Button"
          >
            <div className="ringring"></div>

            Stop
          </button>
        </>
      )}

      {props.buttonActive === false && (
        <>
          <button
            className="Button"
            onClick={() => startStream()}
          >
            <div className="ringring_offline"></div>
            Start
          </button>
        </>
      )}
    </>
  )
}
