import { useEffect, useState } from "react";
import axios from "axios";
import OBSWebSocket from 'obs-websocket-js';
import './App.css';
import './styles/SceneMap.css';

import statusLogo from "./streamStatus.svg"
import StreamButton from "./utils/StreamButton";
import StreamButton2 from "./utils/StreamButton2";

function App() {
  const obs = new OBSWebSocket();

  const [connected, setConnected] = useState(false);

  const [ipAddress, setIPAddress] = useState("");
  const [port, setPort] = useState("");

  const [preview, setPreview] = useState("");
  const [source, setSource] = useState("");
  const [cpuUsage, setCPU] = useState("");
  const [activeFPS, setFPS] = useState("");

  const [virtualCamStatus, setVirtualCamStatus] = useState(false);
  const [streamingScenes, setStreamingScenes] = useState([]);
  const [sceneItems, setSceneItems] = useState([]);

  const [kickChannel, setKickChannel] = useState("");

  async function connectServer() {
    try {
      const {
        obsWebSocketVersion,
        negotiatedRpcVersion
      } = await obs.connect(`ws://${ipAddress}:${port}`, {
        rpcVersion: 1
      });

      setConnected(true)

      await obs.call("GetCurrentProgramScene").then(async (data) => {
        setSource(String(data.currentProgramSceneName))
        getReplayBuffer(String(data.currentProgramSceneName))
        gatherStats()

        await obs.call("GetSceneItemList", { sceneName: data.currentProgramSceneName }).then((data) => {
          console.log(data)
          setSceneItems(data.sceneItems)
        })
      });

      await obs.call("GetSceneList").then(async (data) => {
        console.log(data)
        setStreamingScenes(data.scenes)
      });

      console.log(`Connected to server ${obsWebSocketVersion} (using RPC ${negotiatedRpcVersion})`)
    } catch (error) {
      console.error('Failed to connect', error.code, error.message);
    }
  }

  const [streamActive, setStreamActive] = useState(false);
  const [timecode, setTimecode] = useState("");

  async function getReplayBuffer(image) {
    try {
      await obs.call("GetSourceScreenshot", {
        sourceName: image,
        imageFormat: "jpg",
        imageWidth: 960,
        imageHeight: 540
      }).then((data) => {
        setPreview(data.imageData)
      }).catch((err) => {
        console.log(err)
      })
    } catch (error) {
      console.log(error)
    }
  }

  async function gatherStats() {
    setInterval(async () => {
      await obs.call("GetStats").then((data) => {
        // console.log(data)
        setFPS(data.activeFps)
        setCPU(data.cpuUsage)
      }).catch((err) => {
        console.log(err)
      })

      await obs.call("GetStreamStatus").then((data) => {
        // console.log(data)
        setStreamActive(data.outputActive)
        setTimecode(data.outputTimecode)
      })

      await obs.call("GetVirtualCamStatus").then((data) => {
        console.log(data)
        setVirtualCamStatus(data.outputActive)
        console.log(data.outputActive)
      })

      await obs.call("GetCurrentProgramScene").then(async (data) => {
        setSource(data.currentProgramSceneName)
        getReplayBuffer(data.currentProgramSceneName)
      });
    }, 1000)
  }

  const [kickConnected, setKickConnected] = useState(false);
  const [kickData, setKickData] = useState(0);
  const [category, setCategory] = useState("");

  async function getKickStats(kickName) {
    axios.get(`https://kick.com/api/v2/channels/${kickName}`).then((data) => {
      setKickData(data.data.followers_count)
      setCategory(data.data.recent_categories[0].category.icon + data.data.recent_categories[0].category.name)
      setKickConnected(true)
      console.log(data.data.followers_count)
    })
  }

  async function changeScene(name) {
    try {
      await obs.call("SetCurrentProgramScene", { sceneName: name })

      // await obs.call("SetCurrentPreviewScene", { sceneName: name })
    } catch (error) {
      await obs.connect(`ws://${ipAddress}:${port}`, { rpcVersion: 1 }).then(async (data) => {
        await obs.call("SetCurrentProgramScene", { sceneName: name })

        // await obs.call("SetCurrentPreviewScene", { sceneName: name })
      })
    }
  }

  return (
    <div className="App">
      <div style={{ display: "flex", padding: "1rem", fontFamily: "Helvetica", fontWeight: 1000, justifyContent: "space-between" }}>
        <header>StreamSync</header>

        <div>
          {connected ?
            (
              <>
                <div style={{ display: "grid", alignItems: "center", justifyContent: "center" }}>
                  🟩 Connected
                </div>
              </>
            )
            :
            (
              <div style={{ color: "red" }}>⚠️ Not Connected</div>
            )}
        </div>
      </div>

      {connected === false && (
        <div style={{ display: "grid", alignItems: "center", justifyContent: "center" }}>
          <div style={{ display: "grid", padding: ".5rem", fontFamily: "Helvetica", fontWeight: 1000, gap: "1rem", alignItems: 'center' }}>
            <div>OBS IP Address</div>
            <input
              style={{ display: "flex", padding: ".5rem", fontFamily: "Helvetica", fontWeight: 1000 }}
              onChange={(x) => setIPAddress(x.target.value)}>

            </input>
          </div>

          <div style={{ display: "grid", padding: ".5rem", fontFamily: "Helvetica", fontWeight: 1000, gap: "1rem", alignItems: 'center' }}>
            <div>Port</div>
            <input
              style={{ display: "flex", padding: ".5rem", fontFamily: "Helvetica", fontWeight: 1000 }}
              onChange={(x) => setPort(x.target.value)}>

            </input>
          </div>
        </div>
      )}

      {connected === true && (
        <div style={{ display: "grid", alignItems: "center" }}>
          {connected && (
            <div style={{ display: "flex", justifyContent: "center", gap: ".5rem" }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: ".5rem", padding: ".5rem" }}>
                <img alt="stream_status" src={statusLogo}></img> {streamActive ? "Streaming" : "Offline"}
              </div>

              <div style={{ display: 'flex', alignItems: 'center', gap: ".5rem", padding: ".5rem" }}>
                <img alt="stream_status" src={statusLogo}></img> {virtualCamStatus ? "V-Cam On" : "V-Cam Off"}
              </div>
            </div>
          )}

          {streamActive === true && (
            <>
              <div style={{ color: "red", fontSize: "1.5rem", fontWeight: "bolder" }}>LIVE NOW</div>
              <div style={{ display: 'flex', justifyContent: "center" }}>
                {timecode}
              </div>
            </>
          )}

          <div style={{ display: 'flex', justifyContent: 'center', gap: ".5rem", padding: "1rem" }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: ".5rem", padding: ".5rem" }}>
              <div>CPU: {Number(cpuUsage).toFixed(2)}%</div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: ".5rem", padding: ".5rem" }}>
              <div>FPS: {Number(activeFPS).toFixed(2)}</div>
            </div>
          </div>

          <div>
            <div style={{ display: 'grid', fontFamily: "Helvetica", fontWeight: 1000, fontSize: "1.5rem", padding: "1rem" }}>Source: {source}</div>

            <img style={{ borderRadius: "1rem 1rem", border: "2px white solid", maxWidth: "90%", display: "grid", alignItems: "center", justifyContent: "center", marginLeft: "auto", marginRight: "auto" }} alt="preview" width="100%" src={preview}></img>
          </div>

          <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
            <div style={{ padding: "1rem" }}>
              <div style={{ fontSize: "1.2rem", padding: ".5rem" }}>Stream</div>

              <div style={{ display: "grid", padding: "1rem", gap: ".5rem" }}>
                <StreamButton2 buttonActive={streamActive} ipAddress={ipAddress} port={port} />
              </div>
            </div>

            <div style={{ padding: "1rem" }}>
              <div style={{ fontSize: "1.2rem", padding: ".5rem" }}>Virtual Cam</div>

              <div style={{ display: "grid", padding: "1rem", gap: ".5rem" }}>
                <StreamButton buttonActive={virtualCamStatus} ipAddress={ipAddress} port={port} />
              </div>
            </div>
          </div>

          {streamingScenes.length > 0 && (
            <div className="scene_map">
              {streamingScenes.map(scenes =>
                <button onClick={() => changeScene(scenes['sceneName'])} className={scenes['sceneName'] === source ? "scene_div_2" : "scene_div"}>{scenes['sceneName']}</button>
              )}
            </div>
          )}

          {sceneItems.length > 0 && (
            <div style={{
              display: "grid",
              justifyContent: "center",
              alignItems: "center"
            }}>
              {sceneItems && sceneItems.map(items =>
                <button className={items.sceneItemEnabled ? "scene_div" : "scene_div_2"}>
                  {items.sourceName}
                </button>
              )}
            </div>
          )}

          <div>
            {kickConnected === false && (
              <>
                <input placeholder="Kick Username" style={{ padding: ".5rem", width: "90%" }} type="text" onChange={(x) => setKickChannel(x.target.value)}></input>

                <button style={{ marginLeft: "auto", marginRight: ".5rem", marginTop: ".5rem", marginBottom: ".5rem" }} className="Button" onClick={() => getKickStats(kickChannel)}>Connect Channel</button>
              </>
            )}

            {kickConnected && (
              <>
                <div style={{ display: 'flex', padding: "1rem", justifyContent: "space-between", background: "#212121", margin: "1rem" }}>
                  <div>Followers</div> <div>{kickData}</div>
                </div>

                <div style={{ display: 'flex', padding: "1rem", justifyContent: "space-between", background: "#212121", margin: "1rem" }}>
                  <div>Category</div> <div>{category}</div>
                </div>
              </>
            )}

            <div>
              {kickConnected && (
                <iframe
                  alt="kick_chat"
                  title="kick_chat"
                  height={500}
                  // width={"100%"}
                  style={{
                    minWidth: "90%",
                    maxWidth: "100%",
                    border: "transparent",
                    padding: "1rem"
                  }}
                  src={`https://kick.com/${kickChannel}/chatroom`}
                >

                </iframe>
              )}
            </div>
          </div>
        </div>
      )}

      {ipAddress ? (
        <>
          {connected === false && (
            <div>
              <button
                style={{
                  padding: ".5rem", borderRadius: ".5rem", borderWidth: ".25rem", marginTop: ".5rem"
                }}
                onClick={() => connectServer()}>
                Connect OBS
              </button>
            </div>
          )}
        </>
      ) : (
        <div>
          Enter a IP Address
        </div>
      )}
    </div>
  );
}

export default App;
